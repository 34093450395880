<template>
  <div class='HomeData'>
    <div class="boxItem">
      <div class="box" id='box'></div>
      <div class="box" id='box2'></div>
    </div>
  </div>
</template>

<script>

export default {
  name:"HomeData",
  mounted(){
    this.chartsInit()
  },
  data(){
    return{}
  },
  methods:{
    chartsInit(){
      // 饼状图
      let myChart = this.$echarts.init(document.getElementById('box'));
      let option = {
        title: {
            text: '客户',
            left: 'left',
            top: 10,
            textStyle: {
              color: '#666',
              fontWeight:"normal",
              fontSize:16
            }
        },
        legend: {
          orient: 'vertical',
          right: 20,
          bottom:20,
          textStyle:{
            color:"#666"
          }
        },
        tooltip: {
          trigger: 'item'
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1]
          }
        },
        series: [
          {
            name: '贡献值',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: [
              {value: 335, name: '江苏电力公司',itemStyle:{
                color:"#A5DDF6"
              }},
              {value: 310, name: '南通小龙人视频',itemStyle:{
                color:"#FFB45C"
              }},
              {value: 274, name: '北京同和',itemStyle:{
                color:"#F68F8F"
              }},
              {value: 235, name: '张三',itemStyle:{
                color:"#E0F4A7"
              }}
            ].sort(function (a, b) { return a.value - b.value; }),
            roseType: 'radius',
            label: {
              color: '#666'
            },
            labelLine: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0.3)'
              },
              smooth: 0.2,
              length: 10,
              length2: 20
            },
            animationType: 'scale',
            animationEasing: 'elasticOut',
            animationDelay: function (idx) {
              return Math.random() * 200;
            }
          }
        ]
      };
      option && myChart.setOption(option);
      // 柱状图
      let myChart2 = this.$echarts.init(document.getElementById('box2'));
      let option2 = {
        title: {
          text: '财务',
          left: 'left',
          top: 10,
          textStyle: {
            color: '#666',
            fontWeight:"normal",
            fontSize:16
          }
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'item'
        },
        series:[
          {
            name: '收入',
            type: 'bar',
            itemStyle:{
              color:"#A5DDF6"
            },
            data: [2000,7000,12000,17000,23000,5000,9999,15555,22222,33333,1234,34321]
          }
        ]
      };
      option && myChart2.setOption(option2);
    }
  }
}
</script>

<style lang="scss" scoped>
.HomeData{
  height:800px;
  width:100%;
  .boxItem{
    width:100%;
    height:400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box{
      height: 400px;
      width: 600px;
      box-sizing: border-box;
      border-bottom:1px solid #eee;
      &:first-child{
        border-right:1px solid #eee
      }
    }
  }
}

</style>